<template>
	<div>
		<b-row class="mb-3">
			<b-col>
				<b-alert class="mt-3" variant="warning" :show="showAlertSuccess">
					{{ FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + emailValue }}
				</b-alert>
				<b-alert class="mt-3" variant="info" :show="showAlertError">
					{{ msgModalError }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-if="hiddenInput && loadingFor.verifyEmail">
			<b-col class="text-center"> <b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }} </b-col>
		</b-row>
		<div class="password-forgotten-form">
			<b-row v-if="!hiddenInput" class="mb-3">
				<b-col :cols="$screen.width <= 576 ? 12 : 8">
					<b-input-group class="mb-3">
						<b-input-group-prepend>
							<b-input-group-text>
								<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
							</b-input-group-text>
						</b-input-group-prepend>
						<b-form-input
							v-model="email"
							type="email"
							class="form-control"
							:placeholder="FormMSG(103, 'Email address')"
							aria-describedby="input-email-feedback"
							@input="handleInputEmail"
							style="border-radius: 0px 8px 8px 0px !important"
							:class="{
								'is-invalid': $v.email.$error
							}"
						/>
						<div v-if="$v.email.$error" class="invalid-feedback">
							{{ FormMSG(170, 'Email incorrect') }}
						</div>
					</b-input-group>
				</b-col>
				<b-col :cols="$screen.width <= 576 ? 12 : 4">
					<b-button variant="primary" block :disabled="disableVerifyEmail || loadingFor.verifyEmail || codeVerified" @click="verifyEmail">
						<span v-if="dataToCheck.emailCodeValidation !== 0 && !loadingFor.verifyEmail">
							{{ FormMSG(45, 'Resend code') }}
						</span>
						<span v-if="!loadingFor.verifyEmail && dataToCheck.emailCodeValidation === 0">
							{{ FormMSG(46, 'Verify email') }}
						</span>
						<div class="d-flex justify-content-center align-items-center" v-if="loadingFor.verifyEmail">
							<b-spinner label="Loading..." small></b-spinner>
							<div class="pl-2" style="margin-top: 1px">{{ FormMSG(47, 'Check email') }}</div>
						</div>
					</b-button>
				</b-col>
			</b-row>
			<p v-if="$screen.width <= 576 && showVerificationMail">
				{{ FormMSG(53, 'Enter email verfication code') }}
			</p>
			<b-row v-if="showVerificationMail" class="mb-3">
				<b-col cols="8">
					<fieldset class="form-group" :horizontal="true" id="fieldset-email-validation" aria-labelledby="aria-phone-validation">
						<div class="form-row">
							<legend v-if="$screen.width >= 471" tabindex="-1" class="col-8 bv-no-focus-ring col-form-label" id="aria-email-validation">
								{{ FormMSG(53, 'Enter email verfication code') }} :
							</legend>
							<div class="col">
								<b-form-input
									ref="codeValidation"
									v-model="emailCodeValidation"
									:state="stateError.emailCodeValidation"
									@input="handleInputEmailCodeValidation"
								/>
							</div>
						</div>
					</fieldset>
				</b-col>
				<b-col v-if="stateError.emailCodeValidation !== null && stateError.emailCodeValidation === false" cols="4" class="pt-2">
					<X color="#B11548" :size="20" />
				</b-col>
				<b-col v-if="stateError.emailCodeValidation === true" cols="4" :class="$screen.width <= 576 ? 'pt-2' : 'pt-2'">
					<Check color="#28a745" :size="20" />
				</b-col>
			</b-row>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="showModalSuccess"
			:title="FormMSG(62, 'Validation code')"
			ok-only
			ok-variant="success"
			:ok-title="FormMSG(61, 'Ok')"
			@ok="codeValidationFocus"
			no-close-on-esc
			no-close-on-backdrop
		>
			{{
				forResetPasswordUser === false
					? FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email
					: FormMSG(173, 'Please, enter the code sent to your email, that allow you to access on the next page, and enter your new password.')
			}}
		</b-modal>
		<b-modal
			id="error-email-valid"
			header-class="header-class-modal-doc-package"
			v-model="showModalError"
			:title="FormMSG(127, 'Info')"
			ok-only
			class="modal-success"
			ok-variant="light"
			:ok-title="FormMSG(126, 'Ok')"
			header-bg-variant="info"
			header-border-variant="info"
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
		>
			{{ msgModalError }}
		</b-modal>
	</div>
</template>

<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { emailValidationCode, emailVerificationForPasswordReset, checkLicenseEmailValidationCode } from '@/cruds/registration.crud';
import { emailValid } from '@/shared/utils';
import { X, Check } from 'lucide-vue';
// import gql from 'graphql-tag';
import { store } from '@/store';
import { getNavigatorLanguageValue } from '@/cruds/language.crud';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'EmailValidation',
	components: {
		X,
		Check
	},
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		emailValue: { type: String, default: '' },
		forResetPasswordUser: { type: Boolean, default: false },
		forCreateUser: { type: Boolean, default: false },
		useAlert: { type: Boolean, default: false },
		fromLogin: { type: Boolean, default: false }
	},
	data() {
		return {
			email: '',
			emailCodeValidation: '',
			loadingFor: {
				verifyEmail: false
			},
			dataToCheck: {
				email: '',
				emailCodeValidation: 0,
				licenseId: 0
			},
			stateError: {
				email: null,
				emailCodeValidation: null
			},
			hiddenInput: false,
			showModalSuccess: false,
			showModalError: false,
			showAlertSuccess: false,
			showAlertError: false,
			msgModalError: '',
			userXid: '',
			codeVerified: false
		};
	},
	computed: {
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}

			return false;
		},
		disableVerifyEmail() {
			const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.email)) {
				return true;
			}

			return false;
		}
	},
	watch: {
		emailValue: {
			handler(val) {
				if (val !== '') {
					this.hiddenInput = true;
				} else {
					this.hiddenInput = false;
				}
			},
			immediate: true,
			deep: true
		}
	},
	async mounted() {
		if (this.emailValue) {
			this.email = this.emailValue;
			this.dataToCheck.email = this.emailValue;
			this.stateError.email = null;
			this.stateError.emailCodeValidation = null;
			await this.verifyEmail();
		}
	},
	methods: {
		handleInputEmail(val) {
			this.$v.$touch();
			this.email = val;
			this.emitEvent();
		},
		getLanguuage() {
			if (this.fromLogin) {
				return getNavigatorLanguageValue(navigator.language) === 1 ? 1 : 0;
			} else {
				return store.appLanguage();
			}
		},
		async verifyEmail() {
			try {
				this.loadingFor.verifyEmail = true;

				if (this.forResetPasswordUser) {
					const res = await this.verifyEmailForResetPassword();
					if (res) {
						this.userXid = res;
						this.stateError.email = true;

						await this.sendCodeValidationEmail();
					} else {
						this.stateError.email = false;
						this.msgModalError = this.FormMSG(172, 'If your email is correct, you will receive a validation code.');

						if (!this.useAlert) {
							this.showModalError = true;
						} else {
							this.showAlertError = true;
						}

						this.initDataToCheck();
					}
				} else {
					this.sendCodeValidationEmail();
				}
				this.emailCodeValidation = '';
				this.stateError.emailCodeValidation = null;
				this.loadingFor.verifyEmail = false;
			} catch (error) {
				if (error.mailNotExist && error.mailNotExist === true) {
					this.stateError.email = false;
					this.msgModalError = this.FormMSG(172, 'If your email is correct, you will receive a validation code.');

					if (!this.useAlert) {
						this.showModalError = true;
					} else {
						this.showAlertError = true;
					}

					this.initDataToCheck();
				}
				this.loadingFor.verifyEmail = false;
			}
		},
		async sendCodeValidationEmail() {
			const result = await emailValidationCode({
				email: this.email,
				language: this.getLanguuage()
			});
			this.dataToCheck.licenseId = result.id;
			this.dataToCheck.emailCodeValidation = result.emailValidationCode;
			this.dataToCheck.email = this.email;

			if (!this.useAlert) {
				this.showModalSuccess = true;
			} else {
				this.showAlertSuccess = true;
			}
		},
		initDataToCheck() {
			this.dataToCheck = {
				email: '',
				emailCodeValidation: 0
			};
		},
		async verifyEmailForResetPassword() {
			try {
				const result = await emailVerificationForPasswordReset(this.email);

				return result;
			} catch (error) {
				// console.log(error);
				return null;
			}
		},
		async handleInputEmailCodeValidation() {
			if (this.dataToCheck.licenseId && this.emailCodeValidation) {
				const isValidFromServer = await checkLicenseEmailValidationCode(this.dataToCheck.licenseId, this.emailCodeValidation);
				this.codeVerified = isValidFromServer ? isValidFromServer.CheckLicenseEmailValidationCode : null;
				if (this.codeVerified) {
					this.stateError.emailCodeValidation = true;
					this.$emit('verification-email:check', {
						emailVerified: true,
						email: this.dataToCheck.email,
						xid: this.userXid ? this.userXid.data.ResetPasswordRequest.xid : ''
					});
				} else {
					this.stateError.emailCodeValidation = false;
					this.$emit('verification-email:check', {
						emailVerified: true,
						email: this.dataToCheck.email,
						xid: this.userXid ? this.userXid.data.ResetPasswordRequest.xid : ''
					});
				}
			}
		},
		emitEvent() {
			if (parseInt(this.dataToCheck.emailCodeValidation) === parseInt(this.emailCodeValidation) && this.dataToCheck.email === this.email) {
				this.$emit('verification-email:check', {
					emailVerified: true,
					email: this.dataToCheck.email,
					xid: this.userXid ? this.userXid.data.ResetPasswordRequest.xid : ''
				});
			} else {
				this.$emit('verification-email:check', {
					emailVerified: false,
					email: this.dataToCheck.email,
					xid: this.userXid ? this.userXid.data.ResetPasswordRequest.xid : ''
				});
			}
		},
		codeValidationFocus() {
			setTimeout(() => {
				this.$refs.codeValidation.focus();
			}, 150);
		}
	},
	validations() {
		let pattern = {};

		if (this.forResetPasswordUser) {
			pattern = {
				...pattern,
				email: {
					required,
					emailValid
				}
			};
		}

		return pattern;
	}
};
</script>

<style lang="scss">
#error-email-valid .header-class-modal-doc-package h5 {
	color: #fff;
}
</style>
